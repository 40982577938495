import config from '../config/config'
import { axios } from './index'

const autoQuestionUrl = config.baseUrl + '/question/api/v1/auto/question'

export default {
  //按条件获取 自动出卷 题目
  getQuestionsByPostId(payload) {
    //方便回滚
    // return axios.post(`${autoQuestionUrl}/new/get`, payload)
    return axios.post(`${autoQuestionUrl}/latest/get`, payload)
  }
}
