import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/paper/api/v2/paper'

export default {
  //选择试卷
  getPaperList(payload) {
    return axios.post(`${baseUrl}/list`, payload)
  },
  //判断试卷是否被使用
  testPaperUse(paperId) {
    return axios.get(`${baseUrl}/is/use?paperId=${paperId}`)
  },
  //创建有效试卷放在企业默认试卷库下
  createPaper(payload) {
    return axios.post(`${baseUrl}/create/in-default-bank?examId=${payload.examId}`, payload)
  },
  //判断试卷是否被使用，被使用生成新的试卷，保存考试与所用试卷
  savePaper(payload) {
    return axios.post(`${baseUrl}/save/exam/paper/relation`, payload)
  },
  //创建有效试卷放在企业默认试卷库下
  getPaper() {
    return axios.get(`${baseUrl}/get/default-bank`)
  }
}
