<template>
  <div class="container">
    <div class="paper-left">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="0">
          企业试卷
        </el-menu-item>
        <template>
          <el-menu-item index="1">
            平台试卷
          </el-menu-item>
        </template>
      </el-menu>
      <el-input class="input" v-model="searchForm.searchContent" placeholder="根据名称搜索试卷" @keydown.enter.native="getSearchList">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="getSearchList" />
      </el-input>
      <div class="paper-list">
        <div v-for="data in paperList" :key="data.paperId" class="paper-info" :class="{ menu: paperId === data.paperId }" @click="selectPaper(data)">
          <p class="text-ellipsis">{{ data.paperName }}</p>
          <div class="paper-fraction">
            <span>试卷总分：{{ data.totalPoints }}分</span>
            <span>题目数量：{{ data.questionAmount }}题</span>
          </div>
        </div>
      </div>
    </div>
    <!-- :class="{ 'is-empty': !questionList.length }" -->
    <div class="paper-right">
      <p class="title">{{ paperName }}</p>
      <div class="filter-container">
        <template v-for="(filter, key, index) in filters">
          <div :key="index" class="row">
            <div class="label">{{ filter.label }}:</div>
            <template>
              <div
                v-for="(child, fIndex) in filter.children"
                :key="fIndex"
                class="item default-item"
                :class="{ active: sType === child.label }"
                @click="handleClickFilter(child.label)"
              >
                {{ child.label }}
              </div>
            </template>
          </div>
        </template>
        <div class="yt-question-info" v-loading="loading" element-loading-text="加载中">
          <div class="yt-question-content" v-if="questionList.length">
            <template v-if="questionList.length > 0">
              <div v-for="(item, itemIndex) in questionList" :key="itemIndex">
                <div
                  v-for="(question, index) in item.questionVOS"
                  :key="index"
                  class="item"
                  style="padding: 0;"
                  :id="'anchor-' + question.questionId"
                >
                  <div style="width: 100%;text-align: start;">
                    <!--题目卡片头部-->
                    <div class="header">
                      <div class="question-type">
                        {{ ytConstant.questionType.getLabel(question.questionType) }}
                      </div>
                      <!-- <p class="audit">
                        <YTIcon :href="auditIcons[question.status]"></YTIcon>
                      </p> -->
                    </div>
                    <QuestionCard
                      :question="{
                        ...question,
                        index: index
                      }"
                      :options="{
                        showSize: 7,
                        knowledge: {
                          show: false
                        },
                        ability: {
                          show: false
                        }
                      }"
                    >
                    </QuestionCard>
                    <!--题目卡片底部-->
                    <div class="footer">
                      <div>
                        <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.difficulty / 10 }}</p>
                        <p>
                          {{ ytConstant.difficultType.getLabel(question.difficulty) }}
                        </p>
                        <p slot="reference" class="popover-reference">使用次<span style="letter-spacing: 5px">数:</span>{{ question.useCount }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <template v-else>
            <div class="is-empty">
              <YTIcon :href="'#icon-zanwushuju'" slot="reference" style="width:120px;height:124px;"></YTIcon>
              <p>暂时没有内容</p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newPaper from '@/api/newPaper'
import paperApi from '@api/paper'
import YTIcon from '@components/common/YTIcon'
import QuestionCard from '@components/common/version2/QuestionCard'
export default {
  name: 'SelectPaper',
  components: { YTIcon, QuestionCard },
  data() {
    return {
      activeIndex: '0',
      paperId: 0, // 选中的试卷
      loading: true,
      sType: '全部', //题型筛选
      questionList: [],
      questionListC: [],
      paperInfo: [], //选中的试卷信息
      paperName: '',
      searchForm: {
        isOrganizationPaper: true,
        searchContent: null
      },
      paperList: [],
      auditIcons: ['#icon-yishenhe', '#icon-caogao', '#icon-weishenhe'], //审核图标
      filters: {
        questionType: {
          label: '题型',
          children: [{ label: '全部', value: '' }, ...this.ytConstant.questionType.getMenu()]
        }
      }
    }
  },
  mounted() {
    this.getSearchList()
  },
  methods: {
    getSearchList() {
      newPaper
        .getPaperList(this.searchForm)
        .then(res => {
          if (res.code === 0 && res.res && res.res.length > 0) {
            this.paperList = [...res.res]
            this.paperId = res.res[0].paperId
            this.paperName = res.res[0].paperName
            this.getPaperInfo(res.res[0].paperId)
          }
        })
        .finally(() => {
          this.$emit('closeLoading')
        })
    },
    selectMenu(data) {
      this.searchForm.isOrganizationPaper = !Number(data)
      this.getSearchList()
    },
    selectPaper(data) {
      this.sType = '全部'
      this.paperId = data.paperId
      this.getPaperInfo(data.paperId)
    },
    handleClickFilter(value) {
      this.sType = value
      let list = []
      if (value !== '全部') {
        list = this.questionListC.filter(data => {
          return value === data.name
        })
      } else {
        list = [...this.questionListC]
      }
      this.questionList = [...list]
    },
    //获取题目
    getPaperInfo(id) {
      this.loading = true
      paperApi
        .getAllPaperInfoById(id)
        .then(res => {
          this.paperBankId = res.res.paperBankId
          this.paperName = res.res.name
          this.paperInfo.push(this.$formatTime(res.res.createTime, 'yyyy-MM-dd'))
          this.paperInfo.push(res.res.totalPoints)
          this.paperInfo.push(res.res.questionAmount)
          this.isOwner = res.res.isOwner
          this.questionList = res.res.sectionVOS
          this.questionListC = [...res.res.sectionVOS]
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';
@import '~@/theme/paperDetail';
.container {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: left;
  background: #f3f4f7;
  .paper-left {
    width: 25%;
    background: #ffffff;
    .yt-menu {
      display: flex;
      justify-content: center;
      margin-left: 0 !important;
    }
    .input {
      margin: 12px 0 10px;
      padding: 0 10px;
      ::v-deep .el-input__suffix {
        right: 15px;
        .el-input__icon {
          cursor: pointer;
          line-height: 40px;
        }
      }
    }
    .paper-list {
      height: calc(100% - 120px);
      overflow-y: auto;
      .paper-info {
        height: 57px;
        padding: 0 10px;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        p {
          font-weight: bold;
          line-height: 28px;
          color: #000000;
        }
        .paper-fraction {
          span {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            color: #888888;
            &:first-child {
              padding-right: 10px;
            }
          }
        }
        &:hover {
          background: #edf4ff;
          p,
          span {
            color: #448bff;
          }
        }
      }
    }
  }
  .menu {
    p,
    span {
      color: #448bff !important;
    }
  }
  .paper-right {
    width: 100%;
    margin-left: 10px;
    height: 100%;
    background: #ffffff;
    padding: 10px 0 0 20px;
    .title {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      color: #000000;
    }

    .yt-question-info {
      height: calc(100% - 40px);
      margin-top: 0;
      .body {
        width: calc(100% - 10px) !important;
      }
      .tk-item {
        width: 100% !important;
      }
      ::v-deep img {
        width: 100% !important;
      }
    }
    .filter-container {
      //   border-bottom: 1px solid #e2e4e8;
      height: calc(100% - 44px);
      position: relative;
      .row {
        .flexStyle(flex, flex-start);
        margin-bottom: 10px;
        height: 20px;
        color: #000000;
        &:last-child {
          margin-bottom: 0;
        }
        .label {
          font-size: @small;
          margin-right: 20px;
          height: 20px;
          font-weight: 400;
          line-height: 22px;
          color: #000000;
        }
        .item {
          height: 20px;
          line-height: 20px;
          margin: 0 8px;
          padding: 0 15px;
          font-size: @small;
          cursor: pointer;
        }
        .default-item {
          &:hover {
            border-radius: 11px;
            background: #448bff;
            color: #ffffff;
          }
        }
        .active {
          border-radius: 11px;
          background: #448bff;
          color: #ffffff;
        }
      }
    }
  }
}
.is-empty {
  height: 100%;
  .flexStyle(flex, center, center);
  flex-direction: column;
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #666666 !important;
    margin-bottom: 0 !important;
  }
}
</style>
