<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/manage/exam/list'">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item style="color: #4579EA">{{ breadTitles[step] }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-steps class="steps" :class="{ small: step !== 0 }" :active="step" align-center>
      <el-step title="基本信息" :status="stepStatus(0)" />
      <el-step title="制作试卷" :status="stepStatus(1)" />
      <el-step title="设置考试人员" :status="stepStatus(4)" />
      <el-step title="完成" :status="stepStatus(5)" />
    </el-steps>
    <div class="body" :style="{ height: `calc(100% - ${step !== 0 ? '150' : '190'}px)` }">
      <template v-if="step === 2">
        <component
          :is="makePaperComponents[makePaperType]"
          ref="child"
          :bankId="bankId"
          :examId="examId"
          :paperId="paperId"
          @closeLoading="closeLoading"
          @goToNextStep="goToNextStep"
          @createNewPaper="createNewPaper"
        ></component>
      </template>
      <template v-else-if="step === 3 && makePaperType === 3">
        <div class="random-result">
          <YTIcon :href="'#icon-zanwushuju'" />
          <p>本场考试采用的是随机出卷方式, 没有固定的题目</p>
          <p>您可以配置随机出卷的相关信息, <a @click="editorRandom">点击设置</a></p>
        </div>
      </template>
      <template v-else>
        <component
          :is="stepComponents[step]"
          ref="child"
          :examId="examId"
          :paperId="paperId"
          :isEdit="isEdit"
          @closeLoading="closeLoading"
          @goToNextStep="goToNextStep"
          @createNewPaper="createNewPaper"
          @savePaper="savePaper"
        ></component>
      </template>
    </div>
    <div class="footer">
      <el-button @click="upStep">{{ step === 0 ? '返回' : '上一步' }}</el-button>
      <el-button v-if="step === 0" :loading="saveLoading" @click="savePaperBasicInfo">保存 </el-button>
      <el-button :loading="loading" type="primary" @click="nextStep">{{ step === 5 ? '发布' : '下一步' }}</el-button>
    </div>
  </div>
</template>

<script>
import newExamManageApi from '@api/newExamManage'
import examApi from '@api/exam'
import paperApi from '@api/paper'
import newPaper from '@api/newPaper'

import YTIcon from '@components/common/YTIcon'
import FirstStep from '@components/manage/exam/exam/newPaper/FirstStep'
import SelectPaperMethods from '@components/manage/exam/exam/newPaper/SelectPaperMethods'
import InternalStaff from '@components/manage/exam/exam/newPaper/InternalStaff'
import FinishPage from '@components/manage/exam/exam/newPaper/FinishPage'

import SelectPaper from '@/components/manage/exam/exam/newPaper/SelectPaper'
import RandomDialog from '@components/manage/exam/paper/RandomDialog'
import AutoPaperDialog from '@components/manage/exam/exam/newPaper/AutoPaperDialog'
import EditorExam from '@components/manage/exam/exam/newPaper/EditorExam'

export default {
  name: 'index',
  components: { YTIcon, FirstStep, SelectPaperMethods, InternalStaff, FinishPage, SelectPaper, RandomDialog, AutoPaperDialog, EditorExam },
  data() {
    return {
      step: 0, // 主体步骤
      makePaperType: 0, // 出卷方式
      loading: false, // 下一步按钮防抖
      saveLoading: false, //保存按钮防抖
      isEdit: false, // 是否编辑
      examId: null, // 考试id 当他不为空的时候说明已存在考试，无需重复创建新的考试
      paperId: null, // 试卷id
      bankId: '', // 试卷库id
      name: null, // 考试名称
      breadTitles: ['发布考试', '制作试卷', '制作试卷', '制作试卷', '设置考试人员', '发布考试'],
      stepComponents: ['FirstStep', 'SelectPaperMethods', '', 'EditorExam', 'InternalStaff', 'FinishPage'],
      makePaperComponents: ['SelectPaper', '', 'AutoPaperDialog', 'RandomDialog'],
      makePaperEnums: {
        4: 3, // 随机出卷
        0: 1, // 手动出卷
        6: 2 // 自动出卷
      }
    }
  },
  computed: {
    stepStatus() {
      return stepIndex => {
        return stepIndex > this.step ? 'wait' : 'finish'
      }
    }
  },
  mounted() {
    this.getPaperBank()
    let params = this.$handleParams('newPaperData')
    this.isEdit = params.mode === 'set'
    if (this.isEdit) {
      const step = Number(params.stepIndex)
      this.examId = params.examId
      this.paperId = params.paperId
      this.makePaperType = this.makePaperEnums[params.paperType]
      this.name = params.name
      if (step === 0) {
        this.step = step
        this.$nextTick(() => {
          this.getExamInfo()
        })
      } else if (step === 1) {
        if (this.paperId) {
          this.step = 3
        } else {
          this.step = 1
        }
      } else if (step === 4) {
        // 获取人员
        this.step = step
        this.$nextTick(() => {
          this.$refs.child.determineTheCategory()
        })
      }
    }
  },
  methods: {
    getExamInfo() {
      // 获取考试和试卷信息
      newExamManageApi.examAllInfo(this.examId).then(res => {
        this.name = res.res.name
        this.paperId = res.res.paperId
        if (this.step === 0) {
          // 第一步时填充表单
          this.$refs.child.setForm(res.res)
        }
      })
    },
    getPaperBank() {
      // 获取试卷库id给随机出卷
      newPaper.getPaper().then(res => {
        if (res.code === 0) {
          this.bankId = res.res
        }
      })
    },
    savePaperBasicInfo() {
      // 保存试卷
      this.saveLoading = true
      this.$refs.child.submit(this.isEdit, true)
    },
    editorRandom() {
      // 编辑随机出卷
      this.step = 2
      this.makePaperType = 3
      this.isEdit = true
      this.$nextTick(() => {
        paperApi.getPostRandom(this.paperId).then(res => {
          this.$refs.child.editorRandomPaper(res.res)
        })
      })
    },
    finishExam() {
      // 发布考试
      newExamManageApi
        .postExam(this.examId)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('考试发布成功')
            this.$router.push('/manage/exam/list')
          } else {
            this.$message.error(res.res)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    nextStep() {
      this.loading = true
      if (this.step === 0) {
        // 创建编辑考试信息
        this.$refs.child.submit(this.isEdit)
      } else if (this.step === 1) {
        this.selectPaperType()
      } else if (this.step === 2) {
        if (this.makePaperType === 0) {
          this.paperRelation()
        } else if (this.makePaperType === 2) {
          this.$refs.child.createTest()
        } else if (this.makePaperType === 3) {
          this.$refs.child.define()
        }
      } else if (this.step === 3) {
        this.step += 1
        this.loading = false
        this.$nextTick(() => {
          this.$refs.child.determineTheCategory()
        })
      } else if (this.step === 5) {
        // 完成考试创建
        this.finishExam()
      } else {
        //编辑试卷无需再次保存
        this.step += 1
        this.loading = false
      }
    },
    goToNextStep(data = null) {
      // 提供给子组件调用 下一步方法
      if (this.step === 0) {
        // 第一步点击下一步后保存考试id跟名字
        this.examId = data.examId
        this.name = data.examName
        if (this.paperId) {
          // 存在试卷直接跳到修改题目
          this.step = 3
        } else {
          this.step += 1
        }
        this.loading = false
        return
      } else if (this.step === 1) {
        this.selectPaperType()
        return
      } else if (this.step === 2 && this.makePaperType === 3) {
        this.paperId = data
        this.loading = false
      }
      this.step += 1
    },
    closeLoading() {
      this.loading = false
    },
    upStep() {
      if (this.step === 0) {
        // 返回考试列表
        this.$router.push('/manage/exam/list')
      } else if (this.step === 1) {
        // 返回获取考试信息
        this.step -= 1
        this.$nextTick(() => {
          this.getExamInfo()
        })
      } else if (this.step === 2) {
        // 返回设置出卷方式
        if (this.isEdit) {
          this.step = 0
          this.$nextTick(() => {
            this.getExamInfo()
          })
          return
        }
        this.step -= 1
        this.$nextTick(() => {
          this.$refs.child.activeIndex = this.makePaperType
        })
      } else if (this.step === 3) {
        // 一旦进入该步骤就不可以修改出卷方式
        this.step = 0
        this.$nextTick(() => {
          this.getExamInfo()
        })
      } else if (this.step === 4) {
        this.step -= 1
      } else if (this.step === 5) {
        this.step -= 1
        this.$nextTick(() => {
          this.$refs.child.determineTheCategory()
        })
      }
    },
    selectPaperType() {
      // 选择出卷方式 手动出卷直接跳到选择题目 其他进入各自组件
      this.makePaperType = this.$refs.child.activeIndex
      if (this.makePaperType === 1) {
        // 手动出卷
        this.step = 3
        this.$nextTick(() => {
          this.$refs.child.openByManual()
        })
      } else if (this.makePaperType === 3) {
        this.step += 1
        this.$nextTick(() => {
          this.$refs.child.randomData.paperName = this.name
        })
      } else {
        this.step += 1
      }
    },
    paperRelation() {
      // 选择试卷 关联试卷和考试
      if (!this.$refs.child.paperId) {
        this.$message.warning('请先选择试卷！')
        return
      }
      examApi
        .savePaper(this.examId, this.$refs.child.paperId)
        .then(res => {
          if (res.res === true) {
            this.$message.success('考试与试卷关联成功, 正在获取题目')
            this.paperId = this.$refs.child.paperId
            this.step += 1
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    createNewPaper({ data, ruleId }, callback) {
      this.loading = true
      // 生成试卷
      let payload = {
        name: this.name, //考试名称作为试卷名称
        sectionForms: [],
        status: 1
      }
      if (ruleId) {
        // 自动出卷 规则id
        payload.ruleId = ruleId
      }
      // 获取所选的所有题型
      payload.sectionForms = [
        ...new Set(
          data.map(res => {
            return res.questionType
          })
        )
      ].map(item => {
        return {
          questionType: item,
          name: this.ytConstant.questionType.getLabel(item),
          questionForms: []
        }
      })
      // 按照题型把题目id分类
      data.forEach(question => {
        payload.sectionForms.forEach(section => {
          if (question.questionType === section.questionType) {
            section.questionForms.push({ level: 1, questionId: question.id, score: 1, points: 1 })
          }
        })
      })
      paperApi
        .createNewPaper(this.examId, payload)
        .then(res => {
          if (res.code === 0) {
            this.paperId = res.res
          }
        })
        .finally(() => {
          callback()
          this.loading = false
        })
    },
    //保存试卷
    savePaper(questionPoints = [], callback) {
      this.loading = true
      let payload = {
        paperName: this.name,
        examId: this.examId,
        paperId: this.paperId,
        questionPoints: questionPoints
      }
      paperApi
        .updatePaper(payload)
        .then(res => {
          if (res.code === 0) {
            this.paperId = res.res
          }
        })
        .finally(() => {
          callback()
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding: 10px;
}
.content-wrapper {
  align-items: center;
  height: 35px;
}
.steps {
  height: 100px;
  align-items: center;
  background: #ffffff;
  &.small {
    height: 60px;
    ::v-deep .el-step__icon {
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
    ::v-deep .el-step__title {
      font-size: 12px;
      line-height: 24px;
    }
  }
  ::v-deep .el-step__head {
    &.is-finish {
      .el-step__icon {
        border-color: #448bff;
        color: #448bff;
      }
    }
    .el-step__icon {
      border-color: #e2e4e8;
      color: #b0b0b0;
    }
  }
  ::v-deep .el-step__title {
    font-size: 14px;
    font-weight: 500;
    color: #888888;
    &.is-finish {
      color: #448bff;
    }
  }
}
.body {
  margin-top: 10px;
  background: #fff;
  overflow-y: auto;
}
.random-result {
  .flexStyle();
  .wh();
  flex-direction: column;
  svg {
    font-size: 200px;
  }
  p {
    font-size: 16px;
  }
}
.footer {
  height: 70px;
  line-height: 70px;
  margin-top: 10px;
  background: #fff;
  text-align: center;
}
</style>
